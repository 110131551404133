.block-button {
    margin: 0 auto;
    /*max-width: var(--width-main);*/
    max-width: var(--width-text);
    /*padding: var(--block-space-vertical-small) var(--block-space-horizontal);*/

    padding-left: calc(var(--block-space-horizontal));
    padding-right: calc(var(--block-space-horizontal));
    text-align: center;
}

.block-button a {
    text-decoration: none;
}

.block-button .button:hover {
    text-decoration: none;
}


.block-button.ignore-section-space-x {
    padding-left: 0;
    padding-right: 0;
}

.block-button.top-padding-0 {
    padding-top: 0;
    padding-bottom: 0;
}